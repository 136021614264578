$couleur_principale:#012D41;
$couleur_secondaire:#CCD5D9;
/* Général */
$body_background_color : $couleur_principale;
$body_texte_color: #000;
$link_color : inherit;
$link_hover_color : $couleur_secondaire;

$titlesection_color : #fff;
$titlesection_background_color : $couleur_principale;

/* Header */
$header_background_color : $couleur_principale;
$slogan_color : #fff;

$nav_color : #fff;
$nav_background_color : $couleur_principale;
/*$nav_border_color : #ccc;*/
$sousnav_color: $couleur_principale;
$sousnav_background_color : #fff;
$sousnav_hover_color : #fff;
$sousnav_hover_background_color : $couleur_principale;
$nav_actif_color : $couleur_principale;
$nav_actif_background_color : #fff;
.menu_burger, .menu_burger:hover{color: #222;}

/* Content */
$content_background_color : #fff;
$navarianne_borderbottom_hover_color : darken($couleur_secondaire, 20%);
$navarianne_actif_color : darken($couleur_secondaire, 20%);
$button_background_color : $couleur_principale;
$button_color : #fff;
$button_border_color: $couleur_principale;
$button_hover_color : $couleur_principale;
$button_hover_background_color : #fff;

$formperso_titre_color: #000;


/* accueil */
$slidetext_color: #000;
$slidetext_background_color : #fff;


/* boutique */
$etiquette_color :#fff;
$etiquette_background_color : $couleur_principale;
$produits_mask_color : #fff;
$produits_mask_background_color : rgba($couleur_principale,0.6);
$produits_zoom_color : #fff;
$produits_zoom_background_color : $produits_mask_background_color;
$produits_zoom_hover_color :#fff;
$produits_zoom_hover_background_color : #fff;
$produits_nomprod_background_color: $couleur_principale;
$produits_nomprod_color: #fff !important;
$oldprix_color : $couleur_secondaire;
$prix_color: $couleur_principale;

$ficheproduit_remise_background_color: $couleur_secondaire;
$ficheproduit_oldprix_color:$couleur_secondaire;
$ficheproduit_fleches_color: $couleur_secondaire;

$searchbaricon_color : #fff;
$searchbaricon_background_color : $couleur_secondaire;


/* galerie */
$photo_mask_background_color : $couleur_principale;
$photo_description_color : #fff;
$photo_description_background_color : transparent;
$photo_commentaires_color : #fff;

/* livre d'or */
$msglo_texte_color: $couleur_principale;
$msglo_h4_color: #fff;
$msglo_h4_background_color: $couleur_principale;
$msglo_note_color: #fff;

/* contact */
$form_focus_shadow: 0 0 5px #fff;
$form_focus_bordercolor: #fff;


/* Footer */
$footer_color : #fff;
$footer_background_color : $couleur_principale;

/* Blog */
$background_blog_menu : #fff;
$background_blog_h2 : #fff;
$white : #fff !important;

/* Template Panier */
$panier-background-color:$couleur_principale;
$panier-titre-color:#fff;
$panier-content-background-color:#fff;
$panier-texte-color:$couleur_principale;

/* Flux panier */
$flux-panier_color-shadow:$couleur_principale;

//@import "design/variables";
@import "design/758series/declinaison";